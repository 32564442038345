import HomeCard from "../../../UI/HomeCard"
import Challenges from "./challenges";
const CodeChallengeSection = () => {
  return <HomeCard>
      <h1>Code Challenges</h1>
      <Challenges />

      
  </HomeCard>;
};
export default CodeChallengeSection;
